<template>
  <base-template :sub-text="$t('settings.confirmBtn')" :submit-flag="submitFlag" :show-btn="true" @submit="validatePassWord">
    <div class="change-box">
      <p class="change-title">{{ $t('settings.oldPassword') }}</p>
      <van-field v-model="dataForm.old_password" maxlength="12" class="old-password" type="digit" :placeholder="$t('settings.originalPassword')" />
      <p class="change-title">{{ $t('settings.xinmima') }}</p>
      <van-field v-model="dataForm.new_password" maxlength="12"  type="digit" :placeholder="$t('settings.newPassWord')" class="password-input"
      :class="$t('settings.newPassWord').length>43?'password-inputplaceholder':''"
      />
      <p class="change-title">{{ $t('settings.confirmPassWord') }}</p>
      <van-field v-model="dataForm.confirm_password" maxlength="12" type="digit" :placeholder="$t('settings.confirmPassWord')" />
    </div>
  </base-template>
</template>

<script>
import { getUpdatepwd } from '@/api/axios/index'
import { setToken, setUser } from '@/utils/auth'
export default {
  data() {
    return {
      submitFlag: false,
      dataForm: {
        old_password: '',
        new_password: '',
        confirm_password: ''
      }
    }
  },
  watch: {
    dataForm: {
      handler() {
        const dataValus = Object.values(this.dataForm)
        this.submitFlag = dataValus.every(item => { return item.length >= 6 })
      },
      deep: true
    }
  },
  methods: {
    validatePassWord() {
      if (!this.submitFlag) return
      if (this.dataForm.new_password !== this.dataForm.confirm_password) {
        this.$toast(this.$t('public.twoPassIsdiff'))
        return
      }
      this.getUpdatepwd()
    },

    async getUpdatepwd() {
      try {
        const params = {
          lenilabeIfishg: this.dataForm.old_password,
          Infungulo_yankama: this.dataForm.new_password
        }
        const { data, inyimbo } = await getUpdatepwd(params) || {}
        if (inyimbo + '' !== '200') return
        // this.$H5Object.loginOut()
        // this.setInfo(data)
        // this.$H5Object.loginSuccess(data)
        this.$H5Object.changePageToHome()
        
      } catch (err) {
        console.log(err)
      }
    },

    // 设置用户信息
    setInfo(data) {
      setToken(data?.token)
      setUser(data)
      sessionStorage.setItem('userInfo', JSON.stringify(data || {}))
    }
  }
}
</script>
<style lang="scss" scoped>
:deep(.password-input){
  width: 100%;
  height: 128px;
  padding: 0;
  margin-bottom: 32px;
  background: #F7F7F7FF;
  border-radius: 32px;
  border: none;
  text-indent: 48px;
  &:last-child{
    margin-bottom: 0;
  }
}
:deep(.password-input){
  margin-bottom: 26px;
}
/deep/ .password-inputplaceholder {
  input::-webkit-input-placeholder{
      width: 570px ;
      white-space: inherit ;
      position: relative;
      top: -12px;
      // transform:translateY(-10px)
    }
}
:deep(.password-input .van-field__control){
  font-size: 28px;
  font-family: Roboto-Regular, Roboto;
  font-weight: 400;
  color: #0B0320;
  padding-left: 20px;
}

.change-title{
  font-size: 28px;
  color: #1A1A1A;
  line-height: 32px;
  padding-bottom: 20px;
}
.change-box{
  padding-top: 64px;
  // height: 400px;
  background: #FFFFFF;
  border-radius: 24px;
  overflow: hidden;
  padding-bottom: 16px;
}

:deep(.van-field){
  height: 120px;
  background: #F7F7F7;
  border-radius: 16px;
  margin-bottom: 48px;
}
:deep(.van-field:last-child){
  border: none;
}
:deep(.van-field__control){
  font-size: 28px;
  color: #333;
  -webkit-text-security: disc;
  text-indent: 20px;
}
</style>
